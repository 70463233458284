import { useFormikContext } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
  MaintenanceTypeDto,
  PriceInformationObjectDto,
  RopeFallProtectionSystemDto,
} from '@wartungshelden/shared-types';

import { InfoMessageBox } from '../../../../components/Basics/MessageBox';
import { PSAGA_SYSTEM_TYPES } from '../../../../constants/MaintenanceConstants';
import { isRopeSystemDto } from '../../../../guards/isFallProtectionSystem';
import {
  isClimbingProtectionDto,
  isFallProtectionDto,
  isPPEDto,
  isRailSystemDto,
} from '../../../../guards/isMaintenanceType';
import PriceCalculationContext from '../PriceCalculationContext';
import PriceCalculationLayout from '../PriceCalculationLayout';
import {
  OBJECT_INFORMATION_SESSION_KEY,
  PriceCalculationState,
} from '../utils/utils';
import CreateMaintenanceObjectMenu from './CreateMaintenanceObjectMenu';
import AddPsaModal from './modal/AddPsaModal';
import PriceCalculationMaintenanceObjectModal from './modal/PriceCalculationMaintenanceObjectModal';
import PriceOverviewRows from './priceRow/PriceOverviewRows';

const shouldSplitRopeSystem = (
  priceInformationObject: PriceInformationObjectDto
) => {
  if (isFallProtectionDto(priceInformationObject?.object.type)) {
    const system = priceInformationObject.object.type
      .system as RopeFallProtectionSystemDto;
    const { singleAnchors } = system;
    return singleAnchors > 9;
  }
  return false;
};

const splitRopeSystem = (
  priceInformationObject: PriceInformationObjectDto
): PriceInformationObjectDto[] => {
  if (
    isFallProtectionDto(priceInformationObject?.object.type) &&
    isRopeSystemDto(priceInformationObject.object.type.system) &&
    shouldSplitRopeSystem(priceInformationObject)
  ) {
    const { system } = priceInformationObject.object.type;
    const { singleAnchors } = system;

    const ropeSystemObject: PriceInformationObjectDto = {
      ...priceInformationObject,
      object: {
        ...priceInformationObject.object,
        type: {
          ...priceInformationObject.object.type,
          system: {
            ...system,
            singleAnchors: 0,
          },
        },
      },
    };

    const singleAnchorsObject: PriceInformationObjectDto = {
      ...priceInformationObject,
      object: {
        ...priceInformationObject.object,
        type: {
          type: 'fall_protection',
          system: {
            system: 'single_anchor',
            singleAnchors,
          },
          access: priceInformationObject.object.type.access,
        },
        id: uuidv4(),
        manufacturer: priceInformationObject.object.manufacturer,
      },
    };

    return [ropeSystemObject, singleAnchorsObject];
  }

  return [priceInformationObject];
};

const PriceCalculationPageForm: React.FC = () => {
  const [showAddPSAModal, setShowAddPSAModal] = useState(false);
  const { values, setFieldValue, submitForm } =
    useFormikContext<PriceCalculationState>();

  const {
    setIsUpdating,
    isPriceCalculationMaintenanceObjectModalVisible,
    setIsPriceCalculationMaintenanceObjectModalVisible,
    selectedPriceInformationObject,
    setSelectedPriceInformationObject,
    isUpdating,
    setPriceWithDocu,
    setPriceWithoutDocu,
  } = useContext(PriceCalculationContext);

  const closePriceCalculationMaintenanceObjectModal = () => {
    setIsPriceCalculationMaintenanceObjectModalVisible(false);
    setIsUpdating(false);
    setSelectedPriceInformationObject(undefined);
  };

  const shouldShowAddPSAModal = (
    priceInformationObjects: PriceInformationObjectDto[],
    currentPriceInformationObjects: PriceInformationObjectDto[]
  ) => {
    const showPsaModal = (type: MaintenanceTypeDto) =>
      (isFallProtectionDto(type) ||
        isRailSystemDto(type) ||
        isClimbingProtectionDto(type)) &&
      !currentPriceInformationObjects.find(
        (priceInformationObject) =>
          isPPEDto(priceInformationObject.object.type) &&
          priceInformationObject.object.type.systemType ===
            PSAGA_SYSTEM_TYPES.PPE_SET.value
      );

    return priceInformationObjects
      .map((priceInformationObject) => priceInformationObject.object.type)
      .some(showPsaModal);
  };

  const saveNewItems = (newItems: PriceCalculationState) => {
    setFieldValue('objectInformation', newItems.objectInformation);
    setFieldValue('combinations', newItems.combinations);
  };

  const onSaveFromModal = (
    newObjects: PriceCalculationState,
    currentObjects: PriceCalculationState
  ) => {
    saveNewItems({
      objectInformation: [
        ...newObjects.objectInformation,
        ...currentObjects.objectInformation,
      ],
      combinations: [
        ...newObjects.combinations,
        ...currentObjects.combinations,
      ],
    });

    setShowAddPSAModal(
      shouldShowAddPSAModal(
        newObjects.objectInformation,
        values.objectInformation
      )
    );
    closePriceCalculationMaintenanceObjectModal();
  };

  useEffect(() => {
    const { strategy, ...objectInfo } = values;

    sessionStorage.setItem(
      OBJECT_INFORMATION_SESSION_KEY,
      JSON.stringify(objectInfo)
    );

    if (values.objectInformation.length) {
      submitForm();
    }
  }, [values.objectInformation]);

  return (
    <>
      <PriceCalculationLayout
        topComponent={
          <>
            <div className="mt-2 mb-2">
              <InfoMessageBox label="Einzelpreise und Gesamtpreis enthalten 7,5% Rabatt" />
            </div>
            <CreateMaintenanceObjectMenu
              onPreselectedObjectClick={(preselectedObject) => {
                setSelectedPriceInformationObject(preselectedObject);
                setIsPriceCalculationMaintenanceObjectModalVisible(true);
              }}
            />
          </>
        }
        bottomComponent={
          <PriceOverviewRows
            onSaveNewItems={async (newObjectsState) => {
              saveNewItems(newObjectsState);
            }}
          />
        }
      />

      {selectedPriceInformationObject && (
        <PriceCalculationMaintenanceObjectModal
          selectedPriceInformationObject={selectedPriceInformationObject}
          isModalVisible={isPriceCalculationMaintenanceObjectModalVisible}
          setSelectedPriceInformationObject={setSelectedPriceInformationObject}
          isUpdating={isUpdating}
          onSave={async (objectInformation, combinations, currentObjects) => {
            setPriceWithDocu(undefined);
            setPriceWithoutDocu(undefined);
            await onSaveFromModal(
              {
                objectInformation: objectInformation.flatMap(splitRopeSystem),
                combinations,
              },
              currentObjects
            );
          }}
          onClose={closePriceCalculationMaintenanceObjectModal}
        />
      )}
      <AddPsaModal
        showAddPSAModal={showAddPSAModal}
        setShowAddPSAModal={setShowAddPSAModal}
      />
    </>
  );
};

export default PriceCalculationPageForm;
